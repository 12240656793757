.swipeable-list {
  flex: 1;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.swipeable-list-item {
  position: relative;
  transition: max-height 0.5s ease;
  max-height: 1000px;
  transform-origin: top;
  overflow: hidden;
  width: 100%;
  z-index: 0;
}

.swipeable-list-item__content {
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  height: 100%;
  display: flex;
}

.swipeable-list-item__content--return {
  transition: transform 0.5s ease-out;
}

.swipeable-list-item__content--remove {
  transition: transform 0.35s ease-in;
}

.swipeable-list-item__content-right {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  opacity: 0;
}

.swipeable-list-item__content-right--return {
  transition: opacity 0.5s ease-out;
}

.swipeable-list-item__content-left {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  opacity: 0;
  justify-content: flex-end;
}

.swipeable-list-item__content-left--return {
  transition: opacity 0.5s ease-out;
}
